import React, { Component } from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
import fonts from "../styles/fonts"

const TitleStyled = styled.h1`
  ${fonts.avantGardeBold};
  font-size: 60px;
  padding: 4px 0;
  line-height: 32px;
  color: ${colors.blue};
  padding: 20px 0;
  span {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.blue};
    -webkit-text-stroke-width: 1.5px;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 70px;
    line-height: 53px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 80px;
  }
`
const TitleContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  width: 200px;
  text-align: center;
  margin: 0 auto;
  @media (min-width: ${breakpoints.md}) {
    width: 300px;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 340px;
  }
`

const TextStyled = styled.p`
  ${fonts.avantGardeMedium};
  font-size: 20px;
  line-height: 32px;
  color: ${colors.blue};
  text-align: center;
  padding: 20px 0;
  max-width: 430px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 22px;
    line-height: 44px;
    max-width: 510px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 53px;
    max-width: 560px;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 6200px;
  }
`
const ContainerStyled = styled.div`
  height: 60vh;
  display: grid;
  justify-content: center;
  align-items: center;
`

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Page not found."
          description="The page you are looking for has been removed or relocated."
        />
        <ContainerStyled>
          <TitleContainer>
            <TitleStyled>
              4<span>0</span>4
            </TitleStyled>
          </TitleContainer>
          <TextStyled>
            The page you are looking for has been removed or relocated.
          </TextStyled>
        </ContainerStyled>
      </Layout>
    )
  }
}

export default NotFoundPage
